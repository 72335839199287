<template>
  <div class="oci-login-body">
    <div class="oci-login-position">
      <div class="oci-login-box">
        <b-row>
          <div class="oci-login-logo pt-3">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </div>
        </b-row>
        <b-row>
          <!-- Login-->
          <b-col
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
            >

              <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1 text-left"
              >
                Reset your password
              </b-card-title>
              <b-card-text class="mb-2 text-left">
                Please enter your new password and confirm it.
              </b-card-text>

              <!-- form -->
              <validation-observer
                ref="resetPasswordForm"
                #default="{invalid}"
              >
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="resetPassword"
                >
                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">New Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="newPw"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          type="password"
                          name="new-password"
                          placeholder="New Password"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Confirm Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirm"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model="confirmPw"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          type="password"
                          name="confirm-password"
                          placeholder="Confirm Password"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    Reset Password
                  </b-button>
                </b-form>
              </validation-observer>
              <!-- social buttons -->
              <div class="auth-footer-btn d-flex justify-content-center" />
            </b-col>
          </b-col>
        <!-- /Login-->
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, password,
} from '@validations'
import {
  BCol, BCard, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, VBTooltip, BImg,
} from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCol,
    BLink,
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      status: '',
      newPw: '',
      confirmPw: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      password,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    showMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Success!',
        text: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    resetPasswordCompleted(response) {
      if (response === undefined || response === null) return
      if (response.data=== 'OK') {
        this.showMessage('Password set', false)
        this.$router.push('/login')
        return
      }

      this.showMessage('Reset link has expired, use the reset password link to get a new one', true)
      this.$router.push('/login')
    },    
    async resetPassword() {
      if (this.$route.query.token === undefined || this.$route.query.token === '') {
        this.showMessage('Reset link is invalid, please use the forgotten password link for request a new reset link', true)
        console.log('invalid reset token')
        this.$router.push('/login')
        return
      }

      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          var qs = require('qs');
          const data = qs.stringify({'email': this.$route.query.email, 'token': this.$route.query.token, 'password': this.confirmPw})          
          // var qs = require('qs');
          // axios.post('http://localhost:8000/lawmens/update-password', qs.stringify({'email': this.$route.query.email, 'token': this.$route.query.token, 'password': this.confirmPw}));
          loginMethods.UpdatePassword(data, this.resetPasswordCompleted)
        } else {
          this.showMessage('Passwords do not match or do not match requirements', true)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
